.LoginForm {
  width: 100%;
  text-align: center;
  position: absolute;
  top: calc(50% - 0px);
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  .login-title {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    font-size: 30px;
  }
  .logo {
    background-color: #a9a9a9;
    border-radius: 10px;
    padding: 5px;
    width: 90px;
  }
  .text {
    padding: 5px;
  }
  .PageField {
    margin: 40px;
    text-align: center;
    .FormField {
      min-width: 300px;
    }
  }
}
