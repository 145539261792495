.loading {
  height: 100%;
  width: 100%;
  background-color: rgba(#ffffff, .6);
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
