.ClientRegister {
  width: 90%;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 1%;

  .BackBtn {
    .MuiButton-outlined {
      border: 2px solid (#3f51b5);
      color: #3f51b5;
      background-color: white;
    }
  }

  .ContentContainer {
    margin-left: 20%;
    margin-right: 20%;

    .SubInfo {
      text-align: right;
      font-size: 14px;

      span {
        margin-right: 10px;
      }
    }

    .FormContent {
      margin: 1%;
      padding: 3%;

      .DeleteBtn {
        text-align: right;
        margin-bottom: 10px;

        .MuiButton-outlined {
          border: 2px solid rgba(255, 0, 0, 0.5);
          color: red;
        }
      }

      .MuiGrid-item {
        margin: 0.5% 0;

        .MuiFormLabel-root {
          font-size: 13px;
          padding-left: 10px;
        }

        .MuiTextField-root {
          width: 100%;
          margin: 1% 0;
        }
      }

      .ControlArea {
        margin-top: 3%;
        text-align: center;

        button:nth-child(1) {
          margin-right: 5%;
        }
      }
    }
  }

}